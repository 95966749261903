.app-container {
  display: grid;
  grid-template-rows: auto 30px;
  height: 100vh;

  .footer-container {
    background-color: #ffffff;
    color: #777;
    display: grid;
    align-content: center;
    justify-items: center;
    font-size: 11px;
    padding: 9px 80px;
  }
}
