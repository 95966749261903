@font-face {
  font-family: "GT America";
  src: url(./fonts/GT-America-Standard-Regular.ttf) format("truetype");
}

html,
body {
  height: 100%;
  margin: 0;
}

body {
  background-color: #fafafa;
  font-family: "GT America", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
