.logout-container {
  display: grid;
  grid-auto-rows: auto;
  height: 100%;
  align-content: space-between;
  align-items: center;
  justify-content: center;

  .message-container {
    background-color: #fff;
    border: 1px solid #ddd;
    border-color: #ddd #ddd #d8d8d8;
    box-shadow: 0 2px 0 hsla(0, 0%, 68.6%, 0.12);
    margin-top: 25px;
    height: 550px;
    width: 400px;

    .logo-container {
      border-bottom: 1px solid #ddd;
      padding: 30px 90px;
      text-align: center;
      img {
        display: block;
        margin: 0 auto;
        max-width: 200px;
        max-height: 40px;
      }
    }

    .header-text {
      color: #5e5e5e;
      font-size: 15px;
      font-weight: 600;
      text-align: center;
      margin: 35px auto;
    }

    .callbacks-container {
      font-size: 13px;
      margin: 20px;
      text-align: left;

      .callback-item {
        color: #5e5e5e;
        padding: 2px 0;
        .fa {
          margin-right: 5px;

          &.pending {
            color: #ccc;
          }

          &.complete {
            color: green;
          }
        }
      }
    }
  }
}
