.view-container {
  display: grid;
  grid-template-columns: 40% 60%;
  height: calc(100vh - 30px);

  .login-container {
    display: grid;
    grid-template-rows: auto 100px;

    .app-store-container {
      display: grid;
      grid-template-rows: 20px auto;

      .title {
        font-size: 18px;
        color: #848282;
        text-align: center;
      }

      .items-container {
        display: grid;
        grid-template-columns: 50% 50%;
        justify-items: center;
        margin: 10px auto;

        .item-container {
          margin: 0 5px;

          .app-store-image {
            max-height: 43px;
          }
        }
      }
    }
  }

  .content-container {
    background-color: #fff;
    background-image: url(../public/assets/images/multicard.png);
    background-size: cover;
    cursor: pointer;
  }

  .content-container-vendor {
    background-color: #fff;
    background-image: url(../public/assets/images/multicard.png);
    background-size: cover;
    height: 100%;
    margin: auto;
    width: 80%;
  }
}

#okta-sign-in {
  margin: 25px auto;
}

#okta-sign-in .auth-org-logo {
  max-height: 70px;
  max-width: 250px;
}

#okta-sign-in.no-beacon .auth-header {
  padding-bottom: 15px;
  padding-top: 15px;
}