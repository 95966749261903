.content-container {
  display: grid;
  justify-content: center;

  .password-container {
    background-color: #fff;
    border: 1px solid #ddd;
    border-color: #ddd #ddd #d8d8d8;
    box-shadow: 0 2px 0 hsla(0, 0%, 68.6%, 0.12);

    display: flex;
    flex-direction: column;

    margin-top: 25px;
    height: 550px;
    width: 400px;
    .logo-container {
      border-bottom: 1px solid #ddd;
      padding: 30px 90px;
      text-align: center;
      img {
        display: block;
        margin: 0 auto;
        max-width: 200px;
        max-height: 40px;
      }
    }

    .input-container {
      display: flex;
      flex: 1;
      flex-direction: column;
      margin-top: 20px;

      .input-item-container {
        margin: 10px auto;

        label {
          display: block;
        }

        input {
          border: 1px solid #ccc;
          border-radius: 5px;
          display: block;
          font-size: 14px;
          padding: 5px 10px;
        }
      }
    }

    .buttons-container {
      display: grid;
      grid-template-columns: 150px 150px;
      grid-column-gap: 10px;
      justify-content: center;
      margin-bottom: 10px;

      button {
        padding: 5px 10px;
      }
    }
  }
}
